import { S, SA, DC } from '../utilities';


const createChart = (canv) => {
    let listItems = SA('.moreContent .center .inline_list a, .moreContent .center .pills a');

    const backgroundColor = [
        "#f44336",
        "#e91e63",
        "#ec407a",
        "#9c27b0",
        "#7e57c2",
        "#673ab7",
        "#3f51b5",
        "#2196f3",
        "#03a9f4",
        "#00bcd4",
        "#009688",
        "#26a69a",
        "#4caf50",
        "#8bc34a",
        "#cddc39",
        "#c6ff00",
        "#ffeb3b",
        "#ffc107",
        "#ffa726",
        "#ff9800",
        "#ff7043",
        "#ff5722",
        "#8d6e63",
        "#795548",
        "#9e9e9e",
        "#78909c",
        "#607d8b",
        "#37474f"
    ];
    const mergedData = [...listItems].map((el, i) => ({
        label: el.innerText,
        url: el.getAttribute('href'),
        backgroundColor: backgroundColor[i],
        score: el.getAttribute('data-score') ? el.getAttribute('data-score') : 100,
    }));

    
    let data = {
        datasets: [{
            data: mergedData.map( ( item, i ) => item.score ),
            backgroundColor
        }],
        labels: mergedData.map( item => item.label )
    };

    const options = {
        responsive: true,
        legend: {
            position: 'right',
        },
        title: {
            display: true,
            text: 'Competenties'
        },
        scale: {
            ticks: {
                beginAtZero: true
            },
            reverse: false
        },
        animation: {
            animateRotate: false,
            animateScale: true
        },
        onClick: e => {
            const activePoints = chart.getElementsAtEvent(e);
            const selectedIndex = activePoints[0];
            const find = mergedData.filter( item => selectedIndex && item.label === selectedIndex._view.label);
            if (find[0]) {
                const goto = find[0];
                window.location.href = goto.url;
            }
        }
    };
    
    
    const chart = new Chart(canv, {
        data,
        type: 'polarArea',
        options
    });
}


export default () => {

    const container = S('.moreContent .center');
    const list = S('.moreContent .center .inline_list, .moreContent .center .pills');
    if (container && list) {
        const canv = DC('canvas', 'canvas_el');
        container.insertBefore(canv, list);
        canv.setAttribute('width', 400);
        canv.setAttribute('height', 400);
        createChart(canv);
    }
}