import "babel-polyfill";
import "isomorphic-fetch";
import Menu from './modules/Menu';
import Blocks from './modules/Blocks';
import Etalage from './modules/Etalage';
import Slider from './modules/Slider';
import Accordion from './modules/Accordion';
import ProductsView from './modules/ProductsView';
import LightBox from './modules/LightBox';
import MenuWatcher from './modules/MenuWatcher';
import Pies from './modules/Pies';
import Counter from './modules/Counter';
import './app.sss';


const Index = () => {
  Menu();
  Blocks();
  Etalage();
  Slider();
  Accordion();
  ProductsView();
  LightBox();
  MenuWatcher();
  Pies();
  Counter();
}


window.addEventListener('load', Index);
