import { S, SA, ACL, RCL} from '../utilities';

export default () => {
    const counter = S('.counter_row');
    const counters = SA('.counter_row .coll .title');

    

    if (counter) {
        const amounts = [...counters].map( el => el.innerText );

        const calc = () => {
            
            [...counters].forEach( (el, i) => {
                const amount = amounts[i];
                let from = 0;
                let incr = parseInt(amount) > 1000 ? 550 : parseInt(amount) >= 20 ? 5 : 1;
                let timer;
                clearInterval(timer);

                timer = setInterval(() => {
                    from += incr;
                    el.innerText = from;
                    if (from >= amount) {
                        el.innerText = amount;
                        clearInterval(timer);
                    }
                    
                }, 50);
            });
        };

        if (!!window.IntersectionObserver) {
            const observer = new IntersectionObserver((entries, observer) => { 
                entries.forEach( entry => {
                    if (entry.intersectionRatio < 1) {
                        RCL(counter, 'reached');
                    } else {
                        calc();
                        ACL(counter, 'reached');
                        
                    }
                });
            }, {
                rootMargin: '200px',
                threshold: 1.0
            });
    
            observer.observe(counter)
        }
    }

}